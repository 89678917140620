import { Link } from 'gatsby';
import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  height: 60px;
  padding: 0 30px;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    justify-content: center;
    max-width: 720px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    max-width: 1080px;
  }
`;

export const LogoWrapper = styled(Link)`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  cursor: pointer;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    margin-right: 80px;
  }
`;

export const HamburgerWrapper = styled.div`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  z-index: 2;
  cursor: pointer;
`;

export const MenuItemsWrapper = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    width: initial;
  }
`;
