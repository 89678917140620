import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

interface MetaData {
  name: string;
  content: string;
}

interface ComponentProps {
  description?: string;
  lang?: string;
  meta?: MetaData[];
  title: string;
}

const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        robots
        keywords
        viewPort
        ogUrl
        ogLocale
      }
    }
  }
`;

const SEO = ({ description = '', lang = 'cs', meta = [], title }: ComponentProps) => {
  const { site } = useStaticQuery(pageQuery);

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: site.siteMetadata.ogLocale,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.ogURL,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `robots`,
          content: site.siteMetadata.robots,
        },
        {
          name: `viewport`,
          content: site.siteMetadata.viewPort,
        },
        ...meta,
      ]}
    />
  );
};

export default SEO;
