import styled from 'styled-components';

export const Wrapper = styled.nav`
  position: absolute;
  z-index: 5;
  left: 0;
  top: 90px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.color.background.top};
  > * {
    margin: 20px 0;
  }
`;
