import { graphql, Link, useStaticQuery } from 'gatsby';
import IconQest from 'images/qest.svg';
import React from 'react';
import { Facebook } from 'styled-icons/boxicons-logos/Facebook';
import { Instagram } from 'styled-icons/boxicons-logos/Instagram';
import { Linkedin } from 'styled-icons/boxicons-logos/Linkedin';
import { Twitter } from 'styled-icons/boxicons-logos/Twitter';
import { FooterMenu, FooterMenuItem, ImageWrapper, LogoWrapper, QestIconWrapper, SocialLink, SocialLinksWrapper, Wrapper } from './styled';

const pageQuery = graphql`
  {
    allMenuitemsYaml {
      nodes {
        cs
        link
      }
    }
    footerYaml {
      image {
        publicURL
      }
    }
  }
`;

const Footer = () => {
  const locale = 'cs';
  const data = useStaticQuery(pageQuery);

  const picture = data.footerYaml.image.publicURL;
  const items = data.allMenuitemsYaml.nodes;

  return (
    <footer>
      <Wrapper>
        <ImageWrapper>
          <img src={picture} alt="footer" />
        </ImageWrapper>
        <LogoWrapper>
          <QestIconWrapper>
            <Link to={'/'}>
              <IconQest />
            </Link>
          </QestIconWrapper>
          <FooterMenu>
            {items.map((item: any, index: number) => (
              <FooterMenuItem key={'footerMenu' + index} to={item.link}>
                {item[locale]}
              </FooterMenuItem>
            ))}
          </FooterMenu>
        </LogoWrapper>
        <SocialLinksWrapper>
          <SocialLink>
            <a rel="external" href={'https://www.facebook.com/QestCompany/'} target={'_blank'}>
              <Facebook size={30} />
            </a>
          </SocialLink>
          <SocialLink>
            <a rel="external" href={'https://twitter.com/qest_developers'} target={'_blank'}>
              <Twitter size={30} />
            </a>
          </SocialLink>
          <SocialLink>
            <a rel="external" href={'https://www.instagram.com/qest.cz/'} target={'_blank'}>
              <Instagram size={30} />
            </a>
          </SocialLink>
          <SocialLink>
            <a rel="external" href={'https://www.linkedin.com/company/qest-automation'} target={'_blank'}>
              <Linkedin size={30} />
            </a>
          </SocialLink>
        </SocialLinksWrapper>
      </Wrapper>
    </footer>
  );
};

export default Footer;
