import { graphql, useStaticQuery } from 'gatsby';
import IconHamburger from 'images/hamburger.svg';
import IconQest from 'images/qest.svg';
import React, { useEffect, useState } from 'react';
import { DefaultTheme, withTheme } from 'styled-components';
import { HamburgerWrapper, LogoWrapper, MenuItemsWrapper, Wrapper } from './styled';
import MenuDropdown from './subComponents/MenuDropdown';
import MenuItems from './subComponents/MenuItems';

interface Props {
  theme: DefaultTheme;
}

enum ScreenType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

const pageQuery = graphql`
  {
    allMenuitemsYaml {
      nodes {
        cs
        link
      }
    }
  }
`;

const NavigationBar = ({ theme }: Props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [screenType, setScreenType] = useState<ScreenType | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setScreenType(window.innerWidth > theme.layout.md ? ScreenType.DESKTOP : ScreenType.MOBILE);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data = useStaticQuery(pageQuery);
  const items = data.allMenuitemsYaml.nodes;

  if (!screenType) {
    return <></>;
  }

  return (
    <>
      {screenType === ScreenType.DESKTOP && (
        <Wrapper>
          <LogoWrapper to="/">
            <IconQest />
          </LogoWrapper>
          <MenuItemsWrapper>
            <MenuItems data={items} />
          </MenuItemsWrapper>
        </Wrapper>
      )}
      {screenType === ScreenType.MOBILE && (
        <Wrapper>
          <LogoWrapper to="/">
            <IconQest />
          </LogoWrapper>
          {mobileMenuOpen && <MenuDropdown data={items} />}
          <HamburgerWrapper>
            <IconHamburger onClick={() => setMobileMenuOpen(!mobileMenuOpen)} />
          </HamburgerWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default withTheme(NavigationBar);
