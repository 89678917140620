import React from 'react';
import { MenuItem } from './styled';

interface Props {
  data: {
    cs: string;
    link: string;
  }[];
}

const MenuItems = ({ data: menuItems }: Props) => {
  return (
    <>
      {(menuItems || []).map((info, index) => (
        <MenuItem activeStyle={{ color: '#ee5758' }} key={index} to={info.link}>
          {info.cs}
        </MenuItem>
      ))}
    </>
  );
};

export default MenuItems;
