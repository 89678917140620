import { Link } from 'gatsby';
import styled from 'styled-components';

export const MenuItem = styled(Link)`
  text-transform: uppercase;
  font-weight: 500;
  padding: 10px;
  margin: 0 auto;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.text.secondaryLessTransparent};
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.color.text.accent};
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    justify-content: center;
    padding: 20px;
    font-size: 18px;
  }
`;
