import { Link } from 'gatsby';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 50px;
`;

export const LogoWrapper = styled.div`
  width: 45px;
  height: 45px;
`;

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    flex-direction: row;
  }
`;

export const FooterMenuItem = styled(Link)`
  white-space: nowrap;
  font-size: 16px;
  padding: 15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.text.secondary};
  font-weight: 700;
  font-family: 'Roboto Mono';
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.color.text.accent};
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.md + 20}px) {
    padding: 20px;
  }
`;

export const SocialLinksWrapper = styled.div`
  display: flex;
  color: ${({ theme }) => theme.color.text.secondary};
  margin-top: 450px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    margin-top: 60px;
  }
`;

export const SocialLink = styled.div`
  display: block;
  padding: 20px;
  text-decoration: none;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.color.text.accent};
  }
  a,
  a:visited,
  a:hover,
  a:active {
    color: inherit;
  }
`;

export const QestIconWrapper = styled.div`
  width: 100%;
`;
