import styled, { DefaultTheme, ThemeProps } from 'styled-components';

interface ImageProps extends ThemeProps<DefaultTheme> {
  imageLink: string;
}

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  max-width: calc(100% - 20px);
  padding: 0 10px;
  align-items: center;
  @media screen and (min-width: ${({ theme }) => theme.layout.md}px) {
    max-width: ${({ theme }) => theme.layout.containerWidth.md}px;
  }
  @media screen and (min-width: ${({ theme }) => theme.layout.lg}px) {
    max-width: ${({ theme }) => theme.layout.containerWidth.lg}px;
  }
  margin: 0 auto;
`;

export const SectionWithBackground = styled.section`
  width: 100%;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  background-image: url(${({ imageLink }: ImageProps) => imageLink});
  margin-bottom: 50px;
`;
