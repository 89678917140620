import React from 'react';
import MenuItems from '../MenuItems';
import { Wrapper } from './styled';

interface Props {
  data: {
    cs: string;
    link: string;
  }[];
}

const MenuDropdown = ({ data }: Props) => {
  return (
    <Wrapper>
      <MenuItems data={data} />
    </Wrapper>
  );
};

export default MenuDropdown;
